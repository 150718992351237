import { FC } from 'react';
import { Container, Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { navigationLinks } from '../navigationLinks';
import { FiLogOut } from 'react-icons/fi';

export const Navbar: FC = () => (
  <BootstrapNavbar expand="lg" fixed="top" className="bg-white d-lg-none">
    <Container>
      <Link to={ROUTES.Main()} title="Panel" className="navbar-brand">
        <img src="/CatchUpNinja-1.png" className="header-logo" alt="header-logo" />
      </Link>

      <BootstrapNavbar.Toggle aria-controls="navbar-nav" />
      <BootstrapNavbar.Collapse id="navbar-nav">
        <div className="pt-3">
          <Nav>
            {navigationLinks.map(({ title, route, icon: Icon }) => (
              <NavLink key={route} to={route} className="nav-link d-flex align-items-center py-2">
                <Icon className="me-3" />
                {title}
              </NavLink>
            ))}
            <hr />
            <Link to={ROUTES.Logout()} className="nav-link d-flex align-items-center py-2">
              <FiLogOut className="me-3" />
              Log out
            </Link>
          </Nav>
        </div>
      </BootstrapNavbar.Collapse>
    </Container>
  </BootstrapNavbar>
);
