import { FC } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'react-bootstrap';
import { Standup as StandupModel, Day as DayModel } from '../../interfaces';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';
import { api } from '../../api';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { useTitle } from '../../hooks/useTitle.hook';
import { Loading } from '../../components/Loading';
import { ErrorMessage } from '../../components/ErrorMessage';
import { NotFound } from '../../components/NotFound';
import { EmptyState } from '../../components/EmptyState';
import { UserAnswers } from './components';
import { FormattedDate } from '../../components/FormattedDate';

export const StandupDay: FC = () => {
  const selectedTeam = useSelectedTeam();
  const { standupId, id } = useParams<{ standupId: string; id: string }>();

  const {
    data: standup,
    isFetching: standupIsFetching,
    error: standupError,
  } = useQuery<StandupModel, Error>(['teams', selectedTeam._id, 'standups', standupId], () =>
    api.standups.get(selectedTeam._id, standupId)
  );

  const {
    data: day,
    isFetching: dayIsFetching,
    error: dayError,
  } = useQuery<DayModel, Error>(['teams', selectedTeam._id, 'standups', id, 'days', id], () =>
    api.days.get(selectedTeam._id, standupId, id)
  );

  useTitle(standup?.name, { back: ROUTES.Standup(standup?._id) });

  if ((standupIsFetching || dayIsFetching) && (!standup || !day)) {
    return <Loading />;
  }

  if (standupError) {
    return <ErrorMessage error={standupError} />;
  }

  if (dayError) {
    return <ErrorMessage error={dayError} />;
  }

  if (!standup || !day) {
    return <NotFound />;
  }

  return (
    <>
      <h3>
        <FormattedDate date={day.date} timeZone={standup.timezone} />
      </h3>
      <Row>
        {day.answers.map(answers => (
          <Col xs="12" key={answers._id}>
            <UserAnswers answers={answers} />
          </Col>
        ))}
        {!day.answers.length && <EmptyState />}
      </Row>
    </>
  );
};
