import { FC } from 'react';
import { Answers, User } from '../../../interfaces';
import { Nl2Br } from '../../../components/Nl2Br';
import { Card } from 'react-bootstrap';

interface Props {
  answers: Answers;
}

export const UserAnswers: FC<Props> = ({ answers }) => {
  const user = answers.user as User;

  return (
    <Card body className="mb-3">
      <div className="mb-3">
        <h4 className="mb-0">{user.realName}</h4>
        {user.email && <div className="text-secondary small">{user.email}</div>}
      </div>
      {answers.answers.map((answer, i) => (
        <div key={answer._id} className={i < answers.answers.length - 1 ? 'mb-3' : undefined}>
          <div className="fw-bolder">
            <Nl2Br>{answer.questionContent}</Nl2Br>
          </div>
          <div>
            <Nl2Br>{answer.content}</Nl2Br>
          </div>
        </div>
      ))}
    </Card>
  );
};
