import { FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { api } from '../../api';
import { Form, Input, MultiSelect, Select, SubmitButton, FormArray } from '../../components/Form';
import { useHistoryPush } from '../../hooks/useHistoryPush.hook';
import { ROUTES } from '../../routes';
import timezones from 'timezones-list';
import { useQuery } from 'react-query';
import { SlackChannel, UserTeam } from '../../interfaces';
import { Question, Weekdays } from '../../components/Standup';
import { useTitle } from '../../hooks/useTitle.hook';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';
import { formatUser } from '../../utils/format-user';

const timezoneOptions = timezones.reduce((all, { tzCode, name }) => ({ ...all, [tzCode]: name }), {});
const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const initialValues = {
  name: '',
  timezone: defaultTimezone,
  time: '',
  weekDays: [false, true, true, true, true, true, false],
  users: [],
  // channel: '',
  questions: [
    { content: 'What did you complete since yesterday standup?' },
    { content: 'What do you plan to commit by tomorrow standup?' },
    { content: 'Any blocks your way?' },
  ],
};

const questionInitialValue = {
  content: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required(),
  timezone: Yup.string().required(),
  time: Yup.string().required(),
  weekDays: Yup.array().of(Yup.bool()),
  users: Yup.array().of(Yup.string()).min(1),
  // channel: Yup.string().required(),
  questions: Yup.array()
    .of(Yup.object({ content: Yup.string().required() }))
    .min(1),
});

export const CreateStandup: FC = () => {
  const selectedTeam = useSelectedTeam();

  useTitle('Create Standup', { back: ROUTES.Standups() });

  const onSuccess = useHistoryPush(ROUTES.Standups());
  // const channels = useQuery<SlackChannel[], Error>('slack channels', api.slack.getChannels);
  const members = useQuery<UserTeam[], Error>(['standups', selectedTeam._id, 'members'], () =>
    api.teams.getAllMembers(selectedTeam._id)
  );

  const membersOptions = useMemo<{ [key: string]: string }>(
    () => members.data?.reduce((all, { user }) => ({ ...all, [user._id]: formatUser(user) }), {}) || {},
    [members.data]
  );

  // const channelsOptions = useMemo<{ [key: string]: string }>(
  //   () =>
  //     channels.data?.reduce((all, { id, name }) => ({ ...all, [id]: name }), { '': '- Choose channel -' }) || {
  //       '': '- Choose channel -',
  //     },
  //   [channels.data]
  // );

  const onSubmit = useCallback(
    ({ weekDays, ...data }) =>
      api.standups.create(selectedTeam._id, {
        ...data,
        weekDays: weekDays
          .map((value: boolean, index: number) => (value ? index : undefined))
          .filter((value: number | undefined) => value !== undefined),
      }),
    [api.standups.create, selectedTeam]
  );

  return (
    <div>
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} onSuccess={onSuccess}>
        <Input name="name" label="Name" />
        {/* <Select name="channel" label="Channel" options={channelsOptions} /> */}
        <MultiSelect name="users" label="Participants" options={membersOptions} />
        <Select name="timezone" label="Timezone" options={timezoneOptions} />
        <Input name="time" label="Time" type="time" />
        <Weekdays />
        <FormArray
          name="questions"
          component={Question}
          initialValue={questionInitialValue}
          newElementText="Add new Question"
        />
        <SubmitButton>Save</SubmitButton>
      </Form>
    </div>
  );
};
