import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../../api';
import { useQuery } from 'react-query';
import { ErrorMessage } from '../../components/ErrorMessage';

export const Redirect: FC = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const { data, error } = useQuery<{ link: string }, Error>(['redirect', token], () => api.auth.redirect(token));

  useEffect(() => {
    if (data?.link) {
      history.push(data.link);
    }
  }, [history, data]);

  return (
    <Row className="justify-content-center">
      <Col xs="12" md="8" lg="6" xl="4">
        <h1 className="text-center mb-4">Redirect</h1>
        {error && (
          <div className="text-center">
            <ErrorMessage error={error} />
          </div>
        )}
        {!error && (
          <>
            <div className="text-center">Please wait.</div>
            <div className="text-center mb-4">We are redirecting...</div>
          </>
        )}
      </Col>
    </Row>
  );
};
