import { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { api } from '../../api';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { NotFound } from '../../components/NotFound';
import { useHistoryPush } from '../../hooks/useHistoryPush.hook';
import { useTitle } from '../../hooks/useTitle.hook';
import { Standup as StandupModel, Answers } from '../../interfaces';
import { ROUTES } from '../../routes';
import { SendStatus } from './components';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';

export const SendAnswers: FC = () => {
  const selectedTeam = useSelectedTeam();
  const { id } = useParams<{ id: string }>();
  const {
    data: standup,
    isFetching: isFetchingStandup,
    error: standupError,
  } = useQuery<StandupModel, Error>('standup', () => api.standups.get(selectedTeam._id, id));
  const {
    data: answers,
    isFetching: isFetchingAnswers,
    error: answersError,
  } = useQuery<Answers, Error>(`pendingAnswers`, () => api.answers.getPending(id));

  useTitle(standup ? `${standup.name} - Send answers` : undefined, {
    back: standup ? ROUTES.Standup(standup._id) : undefined,
  });

  const onSuccess = useHistoryPush(ROUTES.Standup(id));

  if ((isFetchingStandup && !standup) || (isFetchingAnswers && !answers)) {
    return <Loading />;
  }

  if (standupError) {
    return <ErrorMessage error={standupError} />;
  }

  if (answersError) {
    return <ErrorMessage error={answersError} />;
  }

  if (!standup) {
    return <NotFound />;
  }

  return (
    <SendStatus
      standup={standup}
      questions={standup.questions}
      currentAnswers={answers}
      onSubmit={api.answers.send}
      onSuccess={onSuccess}
    />
  );
};
