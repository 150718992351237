import { users } from './users';
import { standups } from './standups';
import { slack } from './slack';
import { answers } from './answers';
import { oauth } from './oauth';
import { auth } from './auth';
import { teams } from './teams';
import { days } from './days';
import { stats } from './stats';

export const api = { users, standups, slack, answers, oauth, auth, teams, days, stats };
