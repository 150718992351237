import { FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { api } from '../../api';
import { Form, Input, MultiSelect, Select, SubmitButton, FormArray, Checkbox } from '../../components/Form';
import { useHistoryPush } from '../../hooks/useHistoryPush.hook';
import { ROUTES } from '../../routes';
import timezones from 'timezones-list';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SlackChannel, Standup as StandupModel, User, UserTeam } from '../../interfaces';
import { Question, Weekdays } from '../../components/Standup';
import { useTitle } from '../../hooks/useTitle.hook';
import { Loading } from '../../components/Loading';
import { ErrorMessage } from '../../components/ErrorMessage';
import { NotFound } from '../../components/NotFound';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';
import { formatUser } from '../../utils/format-user';

const timezoneOptions = timezones.reduce((all, { tzCode, name }) => ({ ...all, [tzCode]: name }), {});

const questionInitialValue = { content: '' };

const validationSchema = Yup.object({
  name: Yup.string().required(),
  timezone: Yup.string().required(),
  time: Yup.string().required(),
  weekDays: Yup.array().of(Yup.bool()),
  users: Yup.array().of(Yup.string()).min(1),
  // channel: Yup.string().required(),
  enabled: Yup.bool().required(),
  questions: Yup.array()
    .of(Yup.object({ _id: Yup.string(), content: Yup.string().required() }))
    .min(1),
});

export const EditStandup: FC = () => {
  const selectedTeam = useSelectedTeam();
  const { id } = useParams<{ id: string }>();
  const onSuccess = useHistoryPush(ROUTES.Standups());
  // const channels = useQuery<SlackChannel[], Error>('slack channels', api.slack.getChannels);
  const members = useQuery<UserTeam[], Error>(['standups', selectedTeam._id, 'members'], () =>
    api.teams.getAllMembers(selectedTeam._id)
  );
  const standup = useQuery<StandupModel, Error>('standup', () => api.standups.get(selectedTeam._id, id));

  useTitle(standup.data ? `${standup.data.name} - Edit` : undefined, { back: ROUTES.Standup(id) });

  // const channelsOptions = useMemo<{ [key: string]: string }>(
  //   () =>
  //     channels.data?.reduce((all, { id, name }) => ({ ...all, [id]: name }), { '': '- Choose channel -' }) || {
  //       '': '- Choose channel -',
  //     },
  //   [channels.data]
  // );

  const membersOptions = useMemo<{ [key: string]: string }>(
    () => members.data?.reduce((all, { user }) => ({ ...all, [user._id]: formatUser(user) }), {}) || {},
    [members.data]
  );

  const initialValues = useMemo(
    () => ({
      name: standup.data?.name,
      timezone: standup.data?.timezone,
      time: standup.data?.time,
      users: standup.data?.users.map(user => (user as User)._id),
      channel: standup.data?.channel,
      questions: standup.data?.questions,
      enabled: standup.data?.enabled,
      weekDays: new Array(7).fill(true).map((_, index) => standup.data?.weekDays.includes(index)),
    }),
    [standup]
  );

  const onSubmit = useCallback(
    ({ weekDays, ...data }) =>
      api.standups.update(selectedTeam._id, id, {
        ...data,
        weekDays: weekDays
          .map((value: boolean, index: number) => (value ? index : undefined))
          .filter((value: number | undefined) => value !== undefined),
      }),
    [id, api.standups.create, selectedTeam]
  );

  if (standup.isFetching && !standup.data) {
    return <Loading />;
  }

  if (standup.error) {
    return <ErrorMessage error={standup.error} />;
  }

  if (!standup.data) {
    return <NotFound />;
  }

  return (
    <div>
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} onSuccess={onSuccess}>
        <Input name="name" label="Name" />
        <Checkbox name="enabled" label="Enabled" />
        {/* <Select name="channel" label="Channel" options={channelsOptions} /> */}
        <MultiSelect name="users" label="Participants" options={membersOptions} />
        <Select name="timezone" label="Timezone" options={timezoneOptions} />
        <Input name="time" label="Time" type="time" />
        <Weekdays />
        <FormArray
          name="questions"
          component={Question}
          initialValue={questionInitialValue}
          newElementText="Add new Question"
        />
        <SubmitButton>Save</SubmitButton>
      </Form>
    </div>
  );
};
