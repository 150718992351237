import { FC } from 'react';
import { Card } from 'react-bootstrap';
import { UserTeam } from '../../../interfaces';

interface Props {
  userTeam: UserTeam;
}

export const Member: FC<Props> = ({ userTeam }) => (
  <Card body className="mb-3">
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <div className="d-flex align-items-center">
          <span>{userTeam.user.realName}</span>
          <span className="ms-1 text-secondary small"> - {userTeam.role}</span>
        </div>
        {userTeam.user.email && <div className="text-secondary small">{userTeam.user.email}</div>}
      </div>
      <div className="text-capitalize">{userTeam.status}</div>
    </div>
  </Card>
);
