import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../api';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { useTitle } from '../../hooks/useTitle.hook';
import { UserTeam } from '../../interfaces';
import { Action } from './Action';
import { EmptyState } from '../../components/EmptyState';
import { ROUTES } from '../../routes';
import { Member } from './components';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';
import { useIsOwner } from '../../hooks/useTeamRole.hook';

export const Members: FC = () => {
  const isTeamOwner = useIsOwner();
  const action = useMemo(() => (isTeamOwner ? <Action /> : undefined), [isTeamOwner]);

  useTitle('Members', { action });

  const selectedTeam = useSelectedTeam();

  const { data, error, isFetching } = useQuery<UserTeam[], Error>(['standups', selectedTeam._id, 'members'], () =>
    api.teams.getAllMembers(selectedTeam._id)
  );

  if (isFetching && !data) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      {data?.map(userTeam => (
        <Member key={userTeam._id} userTeam={userTeam} />
      ))}
      {!data?.length && <EmptyState link={ROUTES.InviteMember()} />}
    </>
  );
};
