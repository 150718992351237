import { FC, useMemo } from 'react';
import { User } from '../../interfaces';
import './UserAvatar.scss';

interface Props {
  user: User;
}

export const UserAvatar: FC<Props> = ({ user }) => {
  const initial = useMemo(() => {
    const names = user.realName.split(' ');

    if (names.length > 0) {
      return `${names[0][0]}${names[1][0]}`;
    }

    return user.realName.substring(0, 2);
  }, []);

  if (user.avatar) {
    return <img src={user?.avatar} alt="Avatar" className="panel-avatar img-fluid rounded-circle mb-2" />;
  }

  return (
    <div className="d-inline-block">
      <div className="panel-avatar rounded-circle mb-2 display-4 fw-bold d-flex align-items-center justify-content-center bg-dark text-white">
        {initial}
      </div>
    </div>
  );
};
