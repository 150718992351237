import { Team } from './team';
import { User } from './user';

export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member',
}

export enum InviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
}

export interface UserTeam {
  _id: string;
  name: string;
  user: User;
  team: Team;
  role: UserRole;
  status: InviteStatus;
}
