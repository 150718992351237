import { FC } from 'react';
import { User } from '../../../interfaces';
import { formatUser } from '../../../utils/format-user';

interface Props {
  users: User[];
}

export const Participants: FC<Props> = ({ users }) => {
  return <>{users.map(user => formatUser(user, false)).join(', ')}</>;
};
