import { FC } from 'react';
import * as Yup from 'yup';
import { Card, Col, Row } from 'react-bootstrap';
import { Form, Input, SubmitButton } from '../../components/Form';
import { api } from '../../api';
import { useHistoryPush } from '../../hooks/useHistoryPush.hook';
import { ROUTES } from '../../routes';
import { Link } from 'react-router-dom';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export const Login: FC = () => {
  const onSuccess = useHistoryPush(ROUTES.Profile());

  return (
    <Row className="justify-content-center">
      <Col xs="12" md="8" lg="6" xl="4">
        <Card body className="mb-3">
          <h1 className="text-center mb-4">Login</h1>
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={api.auth.login}
            onSuccess={onSuccess}
          >
            <Input name="email" type="email" label="E-mail" />
            <Input name="password" type="password" label="Password" />
            <div className="text-center">
              <SubmitButton>Log in</SubmitButton>
            </div>
          </Form>
        </Card>
        <div className="text-center">
          Don&apos;t have an account yet? <Link to={ROUTES.SignUp()}>Sign up</Link>
        </div>
      </Col>
    </Row>
  );
  // return (
  //   <Row>
  //     <Col>
  //       <Card body className="text-center">
  //         <h3>Add to Slack</h3>
  //         <a title="Log in via Slack" className="btn btn-primary">
  //           Install
  //         </a>
  //       </Card>
  //     </Col>

  //     <Col>
  //       <Card body className="text-center">
  //         <h3>Log in via Slack</h3>
  //         <a href={href} title="Log in via Slack" className="btn btn-primary">
  //           Log in
  //         </a>
  //       </Card>
  //     </Col>
  //   </Row>
  // );
};
