import { FC, useCallback, useMemo, Fragment } from 'react';
import { Form, FloatingLabel, FloatingLabelProps } from 'react-bootstrap';
import { useField } from 'formik';

interface Props {
  name: string;
  label?: string;
  type?: string;
}

export const Input: FC<Props> = ({ name, label, type = 'text' }) => {
  const [{ onChange, value }, { error, touched }] = useField(name);
  const Label = useMemo(
    () => (label ? (props: FloatingLabelProps) => <FloatingLabel className="mb-3" {...props} /> : Fragment),
    [label]
  );

  const handleChange = useCallback(({ target: { value } }) => onChange({ target: { value, name } }), [onChange, name]);
  const errorMessage = useMemo(() => (touched && error ? error : undefined), [touched, error]);

  // return (
  //   <Form.Group className="form-group mb-3" controlId={`input-${name}`}>
  //     {label && <Form.Label>{label}</Form.Label>}
  //     <Form.Control placeholder={label} type={type} onChange={handleChange} value={value} isInvalid={!!errorMessage} />
  //     <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
  //   </Form.Group>
  // );

  return (
    <Label controlId={`input-${name}`} label={label}>
      <Form.Control placeholder={label} type={type} onChange={handleChange} value={value} isInvalid={!!errorMessage} />
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </Label>
  );
};
