import { FC, PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
}

export const Box: FC<PropsWithChildren<Props>> = ({ to, children }) => {
  return (
    <Link to={to} className="d-block mb-3 text-decoration-none text-dark">
      <Card className="card-standup">
        <Card.Body className="d-flex flex-row align-items-center">
          <div className="flex-grow-1">{children}</div>
          <div className="ps-4">
            <FiChevronRight size={28} strokeWidth="1.5" />
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
};
