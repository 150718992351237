import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from './routes';
import { AuthLayout } from './layouts/AuthLayout';
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp/SignUp';
import { Activate } from './pages/Activate';
import { Redirect as RedirectPage } from './pages/Redirect';
import { AcceptInvite } from './pages/AcceptInvite';

export const Auth: FC = () => (
  <AuthLayout>
    <Switch>
      <Redirect from={ROUTES.Auth()} exact to={ROUTES.Login()} />
      <Route path={ROUTES.Login()} exact component={Login} />
      <Route path={ROUTES.SignUp()} exact component={SignUp} />
      <Route path={ROUTES.Activate()} exact component={Activate} />
      <Route path={ROUTES.Redirect()} exact component={RedirectPage} />
      <Route path={ROUTES.AcceptInvite()} exact component={AcceptInvite} />
    </Switch>
  </AuthLayout>
);
