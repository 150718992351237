import { FC, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga4';
import { ROUTES } from './routes';
import { Panel } from './Panel';
import { Auth } from './Auth';
import { Landing } from './pages/Landing';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

export const App: FC = () => {
  const queryClient = useMemo(() => new QueryClient({ defaultOptions: { queries: { retry: false } } }), []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route path={ROUTES.Main()} exact component={Landing} />
          <Route path={ROUTES.Auth()} component={Auth} />
          <Route path={ROUTES.Profile()} component={Panel} />
        </Switch>
      </Router>
    </QueryClientProvider>
  );
};
