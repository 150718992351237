import { Standup, UpdateStandupDto } from '../../interfaces';
import { CreateStandupDto } from '../../interfaces';
import { client } from '../client';

export const standups = {
  async create(teamId: string, data: CreateStandupDto): Promise<Standup> {
    return client.post(`/teams/${teamId}/standups`, data).then(({ data }) => data);
  },
  async update(teamId: string, id: string, data: UpdateStandupDto): Promise<Standup> {
    return client.patch(`/teams/${teamId}/standups/${id}`, data).then(({ data }) => data);
  },
  async getAll(teamId: string): Promise<Standup[]> {
    return client.get(`/teams/${teamId}/standups`).then(({ data }) => data);
  },
  async get(teamId: string, id: string): Promise<Standup> {
    return client.get(`/teams/${teamId}/standups/${id}`).then(({ data }) => data);
  },
};
