import { InviteMemberDto, UserTeam } from '../../interfaces';
import { client } from '../client';

export const teams = {
  async getAllMembers(teamId: string): Promise<UserTeam[]> {
    return client.get(`/teams/${teamId}/members`).then(({ data }) => data);
  },
  async inviteMember(teamId: string, data: InviteMemberDto): Promise<UserTeam> {
    return client.post(`/teams/${teamId}/members`, data).then(({ data }) => data);
  },
};
