import { ROUTES } from '../../routes';
import { FiLayout, FiUsers } from 'react-icons/fi';
import { IconType } from 'react-icons';

interface NavigationLink {
  title: string;
  route: string;
  icon: IconType;
}

export const navigationLinks: NavigationLink[] = [
  { title: 'Dashboard', route: ROUTES.Standups(), icon: FiLayout },
  { title: 'Members', route: ROUTES.Members(), icon: FiUsers },
  // { title: 'Billing', route: ROUTES.Billing(), icon: FiDollarSign },
  // { title: 'Settings', route: ROUTES.Settings(), icon: FiSettings },
];
