import { AuthLoginDto, AuthSignupDto, User } from '../../interfaces';
import { client } from '../client';

export const auth = {
  async login(data: AuthLoginDto): Promise<User> {
    return client.post('/auth/login', data).then(({ data }) => data);
  },
  async signUp(data: AuthSignupDto): Promise<User> {
    return client.post('/auth/sign-up', data).then(({ data }) => data);
  },
  async activate(token: string): Promise<User> {
    return client.post(`/auth/activate/${token}`).then(({ data }) => data);
  },
  async acceptInvite(token: string): Promise<User> {
    return client.post(`/auth/invite/${token}/accept`).then(({ data }) => data);
  },
  async redirect(token: string): Promise<{ link: string }> {
    return client.post(`/auth/redirect/${token}`).then(({ data }) => data);
  },
};
