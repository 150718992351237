import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { api } from '../../api';
import { User } from '../../interfaces';
import { useQuery } from 'react-query';
import { ErrorMessage } from '../../components/ErrorMessage';

export const Activate: FC = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const { data, error } = useQuery<User, Error>(['activate', token], () => api.auth.activate(token));

  useEffect(() => {
    if (data) {
      history.push(ROUTES.Profile());
    }
  }, [history, data]);

  return (
    <Row className="justify-content-center">
      <Col xs="12" md="8" lg="6" xl="4">
        <h1 className="text-center mb-4">Account Activation</h1>
        {error && (
          <div className="text-center">
            <ErrorMessage error={error} />
          </div>
        )}
        {!error && (
          <>
            <div className="text-center">Please wait.</div>
            <div className="text-center mb-4">We are activating your account...</div>
          </>
        )}
      </Col>
    </Row>
  );
};
