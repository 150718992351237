import { FC, useState, useEffect, useMemo, PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';
import { Button } from 'react-bootstrap';

interface Props {
  loadingText?: string;
}

export const SubmitButton: FC<PropsWithChildren<Props>> = ({ children, loadingText = 'Saving...' }) => {
  const { isSubmitting } = useFormikContext();
  const [isLoading, setLoading] = useState(isSubmitting);

  useEffect(() => {
    if (isSubmitting !== isLoading) {
      const timeout = setTimeout(() => setLoading(isSubmitting), isSubmitting ? 300 : 0);
      return () => clearTimeout(timeout);
    }
  }, [isSubmitting, isLoading]);

  const title = useMemo(() => (isLoading ? loadingText : children), [isLoading, loadingText, children]);

  return (
    <Button disabled={isSubmitting} variant="outline-primary" type="submit">
      {title}
    </Button>
  );
};
