import { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { UserAvatar } from '../../../components/UserAvatar';
import { Nav } from 'react-bootstrap';
import { ROUTES } from '../../../routes';
import { User } from '../../../interfaces';
import { navigationLinks } from '../navigationLinks';

interface Props {
  me: User;
}

export const PanelMenu: FC<Props> = ({ me }) => (
  <div className="d-none d-lg-flex flex-column flex-shrink-0 p-3 panel-menu" style={{ width: '280px' }}>
    <a href="/" className="text-center mb-4 text-decoration-none">
      <span className="fs-4">
        <img src="/CatchUpNinja-1.png" className="header-logo" alt="header-logo" />
      </span>
    </a>
    <div className="mt-4 mb-5 text-center">
      {me && <UserAvatar user={me} />}
      <div className="fw-bolder fs-6">{me.realName}</div>
      <div className="text-secondary text-lighter fs-6">{me.name}</div>
    </div>
    <Nav variant="pills" className="flex-column mb-auto">
      {navigationLinks.map(({ title, route, icon: Icon }) => (
        <NavLink key={route} to={route} className="nav-link d-flex align-items-center py-2 mb-3">
          <Icon className="me-3" />
          {title}
        </NavLink>
      ))}
    </Nav>
    <div>
      <Link to={ROUTES.Logout()} className="nav-link d-flex align-items-center py-2 mb-3">
        <FiLogOut className="me-3" />
        Log out
      </Link>
    </div>
  </div>
);
