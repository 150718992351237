import { FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Card, Col, Row } from 'react-bootstrap';
import { Form, Input, SubmitButton } from '../../components/Form';
import { api } from '../../api';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';

const initialValues = {
  email: '',
  password: '',
  realName: '',
  teamName: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  realName: Yup.string().required(),
  teamName: Yup.string().required(),
});

export const SignUp: FC = () => {
  const [isSuccess, setSuccess] = useState(false);
  const onSuccess = useCallback((_, actions) => {
    setSuccess(true);
    actions.resetForm();
  }, []);

  return (
    <Row className="justify-content-center">
      <Col xs="12" md="8" lg="6" xl="4">
        <Card body className="mb-3">
          <h1 className="text-center mb-4">Sign Up</h1>
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={api.auth.signUp}
            onSuccess={onSuccess}
          >
            <Input name="realName" label="Full Name" />
            <Input name="email" type="email" label="E-mail" />
            <Input name="teamName" label="Team Name" />
            <Input name="password" type="password" label="Password" />
            {isSuccess && (
              <p className="text-success text-center">We sent you an account confirmation link. Check your inbox.</p>
            )}
            <div className="text-center">
              <SubmitButton>Sign up</SubmitButton>
            </div>
          </Form>
        </Card>
        <div className="text-center">
          Already have an account? <Link to={ROUTES.Login()}>Log in</Link>
        </div>
      </Col>
    </Row>
  );
};
