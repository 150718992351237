import { Day } from '../../interfaces';
import { client } from '../client';

export const days = {
  async getAll(teamId: string, standupId: string): Promise<Day[]> {
    return client.get(`/teams/${teamId}/standups/${standupId}/days`).then(({ data }) => data);
  },
  async get(teamId: string, standupId: string, id: string): Promise<Day> {
    return client.get(`/teams/${teamId}/standups/${standupId}/days/${id}`).then(({ data }) => data);
  },
};
