import { useContext, useMemo } from 'react';
import { Team } from '../interfaces/team';
import { useMe } from './useMe.hook';
import { SelectedTeamContext } from '../contexts/selectedTeam.context';

export const useSelectedTeam = (): Team => {
  const me = useMe();
  const { selectedTeamId } = useContext(SelectedTeamContext);

  const selectedTeam = useMemo(
    () => me.teams.find(team => team.team._id === selectedTeamId)?.team || me.teams[0].team,
    [me, selectedTeamId]
  );

  return selectedTeam;
};
