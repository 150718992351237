import { useContext, useMemo } from 'react';
import { useMe } from './useMe.hook';
import { SelectedTeamContext } from '../contexts/selectedTeam.context';
import { UserRole } from '../interfaces';

export const useTeamRole = (): UserRole => {
  const me = useMe();
  const { selectedTeamId } = useContext(SelectedTeamContext);

  const teamRole = useMemo(
    () => me.teams.find(team => team.team._id === selectedTeamId)?.role || me.teams[0].role,
    [me, selectedTeamId]
  );

  return teamRole;
};

export const useIsOwner = (): boolean => {
  const role = useTeamRole();

  return role === UserRole.Owner;
};
