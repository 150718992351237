import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PanelLayout } from './layouts/PanelLayout';
import { CreateStandup } from './pages/CreateStandup';
import { Standup } from './pages/Standup';
import { Standups } from './pages/Standups';
import { SendAnswers } from './pages/SendAnswers';
import { EditStandup } from './pages/EditStandup';
import { Billing } from './pages/Billing';
import { Settings } from './pages/Settings';
import { Logout } from './pages/Logout';
import { Members } from './pages/Members';
import { InviteMember } from './pages/InviteMember';
import { StandupDay } from './pages/StandupDay';
import { ROUTES } from './routes';

export const Panel: FC = () => (
  <PanelLayout>
    <Switch>
      <Redirect from={ROUTES.Profile()} exact to={ROUTES.Standups()} />
      <Route path={ROUTES.Standups()} exact component={Standups} />
      <Route path={ROUTES.CreateStandup()} exact component={CreateStandup} />
      <Route path={ROUTES.SendAnswers()} exact component={SendAnswers} />
      <Route path={ROUTES.EditStandup()} exact component={EditStandup} />
      <Route path={ROUTES.Standup()} exact component={Standup} />
      <Route path={ROUTES.StandupDay()} exact component={StandupDay} />
      <Route path={ROUTES.Billing()} exact component={Billing} />
      <Route path={ROUTES.Settings()} exact component={Settings} />
      <Route path={ROUTES.Members()} exact component={Members} />
      <Route path={ROUTES.InviteMember()} exact component={InviteMember} />
      <Route path={ROUTES.Logout()} exact component={Logout} />
    </Switch>
  </PanelLayout>
);
