import { FC } from 'react';
import { Day as DayModel, Standup } from '../../../interfaces';
import { FormattedDate } from '../../../components/FormattedDate';
import { ROUTES } from '../../../routes';
import { Box } from '../../../components/Box';

interface Props {
  day: DayModel;
  standup: Standup;
}

export const Day: FC<Props> = ({ day, standup }) => (
  <Box to={ROUTES.StandupDay(standup._id, day._id)}>
    <FormattedDate date={day.date} timeZone={standup.timezone} />
  </Box>
);
