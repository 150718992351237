import { FC, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { Button, Container, Nav } from 'react-bootstrap';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiLogOut, FiLayout, FiUsers, FiDollarSign, FiSettings } from 'react-icons/fi';
import { AxiosError } from 'axios';
import { TitleContext } from '../../contexts/title.context';
import { ROUTES } from '../../routes';
import { Loading } from '../../components/Loading';
import { ErrorMessage } from '../../components/ErrorMessage';
import './PanelLayout.scss';
import { useQuery } from 'react-query';
import { User } from '../../interfaces';
import { api } from '../../api';
import { MeContext } from '../../contexts/me.context';
import { SelectedTeamProvider } from '../../contexts/selectedTeam.context';
import { UserAvatar } from '../../components/UserAvatar';
import { Navbar, PanelMenu } from './components';

export const PanelLayout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [back, setBack] = useState('');
  const [action, setAction] = useState<ReactElement>();

  const { data, error, isFetching } = useQuery<User, AxiosError>('me', api.users.getMe);

  const onBackClick = useCallback(() => {
    history.push(back);
  }, [history, back]);

  if (!data && isFetching) {
    return <Loading />;
  }

  if (error) {
    if (error.response?.status === 401) {
      history.push(ROUTES.Auth());
      return null;
    }

    return <ErrorMessage error={error} />;
  }

  if (!data) {
    return <ErrorMessage error={new Error('No user is logged in')} />;
  }

  return (
    <>
      <Navbar />
      <div className="d-flex vh-100">
        <PanelMenu me={data} />
        <div className="vh-100 w-100 d-flex flex-column panel-content">
          {title && (
            <Container className="py-3">
              <div className="d-flex align-items-center justify-content-between px-lg-3">
                <div className="d-flex align-items-center">
                  {back && (
                    <Button variant="link" className="btn-back ps-0" onClick={onBackClick}>
                      <FiArrowLeft size="25" className="me-1" />
                    </Button>
                  )}
                  <h1 className="mb-0 panel-title">{title}</h1>
                </div>
                {action}
              </div>
            </Container>
          )}
          <Container className="overflow-auto pt-2 pb-4">
            <div className="px-lg-3">
              <MeContext.Provider value={{ me: data }}>
                <SelectedTeamProvider>
                  <TitleContext.Provider value={{ title, setTitle, setBack, setAction }}>
                    {children}
                  </TitleContext.Provider>
                </SelectedTeamProvider>
              </MeContext.Provider>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
