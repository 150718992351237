import { createContext } from 'react';
import { User } from '../interfaces';

export interface MeContextInterface {
  me: User;
}

const defaultContext: MeContextInterface = {
  me: {} as User,
};

export const MeContext = createContext<MeContextInterface>(defaultContext);
