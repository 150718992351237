import { FC, useCallback, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { FiUsers, FiMessageSquare, FiSlack } from 'react-icons/fi';
import { NextStandup, Stats } from '../../../interfaces';
import './Charts.scss';
import { toZonedTime, format } from 'date-fns-tz';
import { add, isEqual, startOfDay } from 'date-fns';

interface Props {
  stats: Stats;
}

const WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const TICK_COLOR = '#212529';

export const Charts: FC<Props> = ({ stats }) => {
  const myTimezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

  const plural = useCallback((elements, word) => {
    if (!elements || elements > 1) {
      return `${word}s`;
    }

    return word;
  }, []);

  // const data = useMemo(
  //   () => stats.standupsLastWeek.map(day => ({ Standups: day.standups, WeekDay: WEEK_DAYS[day.weekDay] })),
  //   [stats.standupsLastWeek]
  // );
  const data: any[] = [];

  const isEqualDay = useCallback((date2: Date, date1: Date, timezone: string) => {
    const date1Utc = toZonedTime(date1, timezone);
    const date2Utc = toZonedTime(date2, timezone);

    return isEqual(startOfDay(date1Utc), startOfDay(date2Utc));
  }, []);

  const isToday = useCallback(
    (date: Date, timezone: string) => {
      const tomorrow = new Date();

      return isEqualDay(tomorrow, date, timezone);
    },
    [isEqual]
  );

  const isTomorrow = useCallback(
    (date: Date, timezone: string) => {
      const tomorrow = add(new Date(), { days: 1 });

      return isEqualDay(tomorrow, date, timezone);
    },
    [isEqual]
  );

  const nextStandups = useMemo(() => {
    const today: (NextStandup & { localTime: string })[] = [];
    const tomorrow: (NextStandup & { localTime: string })[] = [];

    stats.nextStandups.forEach(standup => {
      if (isToday(standup.date, myTimezone)) {
        today.push({ ...standup, localTime: format(new Date(standup.date), 'HH:mm') });
      } else if (isTomorrow(standup.date, myTimezone)) {
        tomorrow.push({ ...standup, localTime: format(new Date(standup.date), 'HH:mm') });
      }
    });

    return { today, tomorrow };
  }, [stats.nextStandups, isToday, isTomorrow, myTimezone]);

  return (
    <Row className="dashboard">
      <Col xs="12" md="4" className="d-flex">
        <Card className="flex-grow-1 mb-3 mb-md-0">
          <Card.Body className="d-flex flex-column">
            <div className="text-center">Last week</div>
            {!data.length && (
              <div className="text-secondary small flex-grow-1 d-flex align-items-center justify-content-center">
                No standups last week
              </div>
            )}
            {data.length > 0 && (
              <ResponsiveContainer width="100%" height={120}>
                <BarChart data={data} margin={{ top: 0, bottom: 0, right: 0, left: 0 }}>
                  <Tooltip cursor={false} />
                  <XAxis
                    dataKey="WeekDay"
                    tickCount={data.length}
                    interval={0}
                    tickLine={false}
                    tick={{ fill: TICK_COLOR, fontWeight: 300 }}
                  />
                  <YAxis axisLine={false} tickLine={false} tick={{ fill: TICK_COLOR, fontWeight: 300 }} width={23} />
                  <Bar dataKey="Standups" fill="#212529" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" md="4" className="d-flex">
        <Card className="flex-grow-1 mb-3 mb-md-0">
          <Card.Body className="d-flex flex-column">
            <div className="text-center">Stats</div>
            <Row className="flex-grow-1">
              <Col className="d-flex flex-column align-items-center justify-content-center text-center">
                <FiUsers size="40" strokeWidth="1.3" />
                <div className="mt-2 fw-light text-secondary">
                  {stats.totalStats.members} {plural(stats.totalStats.members, 'member')}
                </div>
              </Col>
              <Col className="d-flex flex-column align-items-center justify-content-center text-center">
                <FiMessageSquare size="40" strokeWidth="1.3" />
                <div className="mt-2 fw-light text-secondary">
                  {stats.totalStats.standups} {plural(stats.totalStats.standups, 'standup')}
                </div>
              </Col>
              <Col className="d-flex flex-column align-items-center justify-content-center text-center">
                <FiSlack size="40" strokeWidth="1.3" />
                <div className="mt-2 fw-light text-secondary">
                  {stats.totalStats.channels} {plural(stats.totalStats.channels, 'channel')}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12" md="4" className="d-flex">
        <Card className="flex-grow-1">
          <Card.Body className="d-flex flex-column">
            <div className="text-center">Next Standups</div>
            <div className="flex-grow-1 d-flex flex-column justify-content-center">
              {!stats.nextStandups.length && (
                <div className="text-secondary small flex-grow-1 d-flex align-items-center justify-content-center">
                  No standups today and tomorrow
                </div>
              )}
              {nextStandups.today.length > 0 && (
                <>
                  <div className="mb-2 calendar-day text-uppercase">Today</div>
                  {nextStandups.today.map((nextStandup, key) => (
                    <div key={key} className="calendar-event fw-light">
                      <div className="d-flex justify-content-between flex-grow-1">
                        <div>
                          {nextStandup.name}{' '}
                          {nextStandup.channel && <span className="text-secondary">#{nextStandup.channel}</span>}
                        </div>
                        <div>{nextStandup.localTime}</div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {nextStandups.tomorrow.length > 0 && (
                <>
                  <div className="mb-2 calendar-day text-uppercase">Tomorrow</div>
                  {nextStandups.tomorrow.map((nextStandup, key) => (
                    <div key={key} className="calendar-event fw-light">
                      <div className="d-flex justify-content-between flex-grow-1">
                        <div>
                          {nextStandup.name}{' '}
                          {nextStandup.channel && <span className="text-secondary">#{nextStandup.channel}</span>}
                        </div>
                        <div>{nextStandup.localTime}</div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
