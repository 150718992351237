import { FC, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { api } from '../../api';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { useTitle } from '../../hooks/useTitle.hook';
import { SlackChannel, Standup as StandupInterface, Stats } from '../../interfaces';
import { Charts, Standup } from './components';
import { Action } from './Action';
import { EmptyState } from '../../components/EmptyState';
import { ROUTES } from '../../routes';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';
import { useIsOwner } from '../../hooks/useTeamRole.hook';

export const Standups: FC = () => {
  const isTeamOwner = useIsOwner();
  const selectedTeam = useSelectedTeam();
  const action = useMemo(() => (isTeamOwner ? <Action /> : undefined), [isTeamOwner]);

  useTitle('Dashboard', { action });

  const { data, error, isFetching } = useQuery<StandupInterface[], Error>(['teams', selectedTeam._id, 'standups'], () =>
    api.standups.getAll(selectedTeam._id)
  );
  const { data: stats, isFetching: isStatsFetching } = useQuery<Stats, Error>(
    ['teams', selectedTeam._id, 'stats'],
    () => api.stats.getStats(selectedTeam._id)
  );
  const { data: channels, isFetching: isChannelsFetching } = useQuery<SlackChannel[], Error>(
    ['teams', selectedTeam._id, 'slack channels'],
    () => api.slack.getChannels(selectedTeam._id)
  );

  if ((isFetching && !data) || (isChannelsFetching && !channels)) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <section className="section">
        <h4>Overview</h4>
        {isStatsFetching && !stats && <Loading />}
        {stats && <Charts stats={stats} />}
      </section>
      <h4>Standups</h4>
      <Row>
        {data?.map(standup => (
          <Col xs="12" xl="6" key={standup._id}>
            <Standup standup={standup} channels={channels || []} />
          </Col>
        ))}
        {!data?.length && <EmptyState link={ROUTES.CreateStandup()} />}
      </Row>
    </>
  );
};
