import { FC, useCallback } from 'react';
import * as Yup from 'yup';
import { api } from '../../api';
import { Form, Input, SubmitButton } from '../../components/Form';
import { useHistoryPush } from '../../hooks/useHistoryPush.hook';
import { ROUTES } from '../../routes';
import { useTitle } from '../../hooks/useTitle.hook';
import { useSelectedTeam } from '../../hooks/useSelectedTeam.hook';

const initialValues = {
  realName: '',
  email: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  realName: Yup.string().required(),
});

export const InviteMember: FC = () => {
  const selectedTeam = useSelectedTeam();

  useTitle('Invite Member', { back: ROUTES.Members() });

  const onSuccess = useHistoryPush(ROUTES.Members());
  const onSubmit = useCallback(data => api.teams.inviteMember(selectedTeam._id, data), [selectedTeam]);

  return (
    <div>
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} onSuccess={onSuccess}>
        <Input name="realName" label="Full  Name" />
        <Input name="email" type="email" label="E-mail" />
        <SubmitButton>Invite</SubmitButton>
      </Form>
    </div>
  );
};
