import { createContext, FC, useState } from 'react';
import { useMe } from '../hooks/useMe.hook';

export interface SelectedTeamContextInterface {
  selectedTeamId?: string;
}

const defaultContext: SelectedTeamContextInterface = {
  selectedTeamId: undefined,
};

export const SelectedTeamContext = createContext<SelectedTeamContextInterface>(defaultContext);

export const SelectedTeamProvider: FC = ({ children }) => {
  const me = useMe();

  const [selectedTeamId, setSelectedTeamId] = useState(
    localStorage.getItem('selected_team_id') || me.teams[0]?.team._id
  );

  return <SelectedTeamContext.Provider value={{ selectedTeamId }}>{children}</SelectedTeamContext.Provider>;
};
