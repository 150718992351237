import { SlackChannel, SlackUser } from '../../interfaces';
import { client } from '../client';

export const slack = {
  async getChannels(teamId: string): Promise<SlackChannel[]> {
    return client.get(`/teams/${teamId}/slack/channels`).then(({ data }) => data);
  },
  async getUsers(teamId: string): Promise<SlackUser[]> {
    return client.get(`/teams/${teamId}/slack/users`).then(({ data }) => data);
  },
};
